import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import SEO from '../components/SEO';

const MainStyles = styled.main`
  text-align: center;
  max-width: 1000px;
  margin: 0 auto;
  margin-top: clamp(25px, 10vw, 50px);
  margin-bottom: clamp(25px, 10vw, 50px);
  @media (max-width: 1024px) {
    max-width: 80vw;
  }
`;

export default function _404() {
  const data = useStaticQuery(graphql`
    query FourOFourQuery {
      email: allSanityHomepage {
        nodes {
          contactEmail
        }
      }
    }
  `);
  const email = data.email.nodes[0].contactEmail;
  return (
    <>
      <SEO title="404" />
      <MainStyles>
        <p>We are sorry, but the page you are looking for cannot be found.</p>
        <p>
          Try heading back to the{' '}
          <Link to="/" className="homeLink">
            <span>Overview</span>
          </Link>{' '}
          or reach out with questions to <a href={`mailto:${email}`}>{email}</a>
          .
        </p>
      </MainStyles>
    </>
  );
}
